<template>
    <div class="g-block g-block--small">
        <div class="g-social g-social--paid">
            <div class="top">
                <div class="conversion-rate conversion-rate--large" v-bind:class="getComponents.paidSocial.FacebookImpressionsBlock.paidClass">
                    <div class="grid-container">
                        <div class="col"><p>{{ formatPercentage(getComponents.paidSocial.FacebookImpressionsBlock.lastMonthsPaid) }}</p></div>
                        <div class="col"><span class="arrow"><i class="fa-solid fa-arrow-up-long"></i></span></div>
                    </div>
                </div>
                <h3>Facebook paid impressions</h3>
            </div>
            <div class="bottom">
                <span class="g-block__icon"><i class="fa-duotone fa-thumbs-up"></i></span>
                <span class="g-block__num">{{ getComponents.paidSocial.FacebookImpressionsBlock.thisMonthsPaid }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
    computed: {
        ...mapGetters("client", ["getClientSlug", "getComponents", "getSocial"]),
    },
    async mounted(){
        try{
            await this.init(this.getSocial.tokens.google);
        }catch(error){
            console.log('mounted error catch', error);
        }
    },
    watch:{
        'getSocial.tokens.google': {
        deep: true,
            handler(newValue) {

                this.init(newValue);

            }
        }
    },
    methods:{
        async init(data){
            try{
                const GA4PropertyID = data.GA4PropertyID;
                const propertyID = data.accountID;
                const accessToken = data.accessToken;

                if(accessToken && GA4PropertyID && propertyID){

                    this.$store.dispatch('client/facebookImpressionsBlockInit');

                }
            }catch(error){
                console.log('init error', error);
            }
        }
    }
}
</script>
